import { get, mapValues } from 'lodash'
import { defineStore } from 'pinia'
import { useTemplatesStore } from './templates'

export const useEditorStore = defineStore({
    id: 'editor',
    state(){
        return {
            formTemplates: {
                abandoned_cart_time_interval_settings: {
                    title: 'Time Interval',
                    meta: 'Time elapsed (in mins.) before a cart is considered as abandoned',
                    icon:'stopwatch',
                    componentIs: 'AbandonedCartTimeIntervalSettings',
                },
                discount_select: {
                    title: 'Discount',
                    meta: 'Select a discount',
                    icon: 'percentage',
                    componentIs: 'DiscountSelect',
                },
                low_inventory_quantity_threshold: {
                    title: 'Quantity threshold.',
                    meta: 'Number value',
                    icon:'edit',
                    componentIs: 'LowInventoryQuantityThresholdForm',
                },
                pop_content: {
                    title: 'Pop Content',
                    meta: 'Customize your pops',
                    icon: 'align-left',
                    componentIs: 'PopTemplateEditor',
                },
                pop_time_settings: {
                    title: 'Time Settings',
                    meta: 'Choose pop display time',
                    icon: 'stopwatch',
                    componentIs: 'PopTimeSettings',
                },
                anonymous: {
                    title: 'Anonymizer Settings',
                    meta: 'Anonymize pops and set default.',
                    icon: 'lock-alt',
                    componentIs: 'AnonymousPopsSettings',
                },
                sessions_cap: {
                    title: 'Display Rules',
                    meta: 'When to show your pop',
                    icon: 'user-check',
                    componentIs: 'SessionsCapForm',
                },
                review_display: {
                    title: 'Display Settings',
                    meta: 'Choose your display settings',
                    icon: 'letter-english-a',
                    componentIs: 'ReviewDisplayForm',
                },
                review_cap: {
                    title: 'Minimum Review Score',
                    meta: 'Choose minimum review score',
                    icon: 'star',
                    componentIs: 'ReviewCapForm',
                },
                summary_display: {
                    title: 'Display Settings',
                    meta: 'Choose your display settings',
                    icon: 'letter-english-a',
                    componentIs: 'SummaryDisplayForm',
                },
                colors: {
                    title: 'Colors',
                    meta: 'Background and text colors',
                    icon: 'palette',
                    componentIs: 'PopColorsForm',
                },
                border_radius: {
                    title: 'Border Radius',
                    meta: 'Change pop border radius',
                    icon: 'square-shape',
                    componentIs: 'PopBorderRadius',
                },
                background_image: {
                    title: 'Background Image',
                    meta: 'Change background image',
                    icon: 'image',
                    componentIs: 'PopBackgroundImageForm',
                },
                manual_review: {
                    title: 'Add a review',
                    meta: 'Create a review manually',
                    icon: 'edit',
                    componentIs: 'ManualReviewForm',
                },
                csv_reviews: {
                    title: 'Import Reviews',
                    meta: 'Upload a CSV, XLSX or XLS',
                    icon: 'edit',
                    componentIs: 'CsvReviewsForm',
                },
                judgeme_review: {
                    title: 'Judge.me',
                    meta: 'Judge.me product reviews app',
                    image: '/icons/brand/judgeme.jpeg',
                    componentIs: 'JudgeMeForm',
                },
                pages_filter: {
                    title: 'Display on Pages',
                    meta: 'Choose pages to show pops',
                    icon: 'align-left',
                    componentIs: 'PopPagesFilterForm',
                },
                pop_type: {
                    title: 'Pop Type',
                    meta: 'Choose a pop type',
                    icon: 'apps',
                    componentIs: 'PopType',
                },
                pop_html_content: {
                    title: 'Pop Content',
                    meta: 'Customize your pop',
                    icon: 'align-left',
                    componentIs: 'PopContent',
                },
                pop_actions: {
                    title: 'Pop Click Event',
                    meta: 'Select what should happens when the pop is clicked',
                    icon: 'setting',
                    componentIs: 'PopActionEvents',
                },
                media_default_templates: {
                    title: 'Pop Templates',
                    meta: 'To help you get started',
                    icon: 'apps',
                    componentIs: 'PopMediaTemplates',
                },
            },
            defaultRouteByHandle: {
                shopify_storefront_add_to_cart: '/pops/rules-and-settings/settings',
                shopify_cart_summary: '/pops/rules-and-settings/settings',
                shopify_order_summary: '/pops/rules-and-settings/settings',
                wix_api_add_to_cart: '/pops/rules-and-settings/settings',
                shopify_api_purchase: '/pops/rules-and-settings/settings',
                wix_api_purchase: '/pops/rules-and-settings/settings',
                recent_visitors: '/pops/rules-and-settings/settings',
                live_visitors: '/pops/rules-and-settings/settings',
                shopify_review: '/pops/rules-and-settings/reviews',
                shopify_discount: '/pops/rules-and-settings/discounts',
                nudge: '/pops/rules-and-settings/settings',
            },
            formsByHandle: {
                shopify_storefront_add_to_cart: {
                    settings: {
                        forms: ['pop_content', 'pop_time_settings', 'anonymous'],
                        props: {
                            anonymous: { allowDisable: false }
                        }
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                shopify_abandoned_cart: {
                    settings: {
                        forms: ['pop_content', 'abandoned_cart_time_interval_settings']
                    } ,
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                shopify_low_inventory: {
                    settings: {
                        forms: ['pop_content', 'low_inventory_quantity_threshold']
                    } ,
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                shopify_cart_summary: {
                    settings: {
                        forms: ['pop_content', 'summary_display'],
                        props: {
                            summary_display: { minimumCountLabel: 'Minimum Add-to-Cart Count' }
                        },
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                shopify_discount: {
                    discounts: {
                        forms: ['discount_select'],
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    },
                },
                wix_api_add_to_cart: {
                    settings: {
                        forms: ['pop_content', 'pop_time_settings', 'anonymous']
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                shopify_api_purchase: {
                    settings: {
                        forms: ['pop_content', 'pop_time_settings', 'anonymous']
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                shopify_order_summary: {
                    settings: {
                        forms: ['pop_content', 'summary_display'],
                        props: {
                            summary_display: { minimumCountLabel: 'Minimum Orders Count' }
                        },
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                wix_api_purchase: {
                    settings: {
                        forms: ['pop_content', 'pop_time_settings', 'anonymous']
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                recent_visitors: {
                    settings: {
                        forms: ['pop_content', 'pop_time_settings', 'sessions_cap']
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                live_visitors: {
                    settings: {
                        forms: ['pop_content', 'pop_time_settings', 'sessions_cap']
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                shopify_review: {
                    settings: {
                        forms: ['review_display', 'review_cap', 'pop_time_settings', 'anonymous']
                    },
                    design: {
                        forms: ['colors', 'border_radius', 'background_image']
                    },
                    reviews: {
                        forms: ['manual_review', 'csv_reviews', 'judgeme_review']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
                nudge: {
                    settings: {
                        forms: ['pop_type', 'pop_html_content', 'pop_actions']
                    },
                    'media-templates': {
                        forms: ['media_default_templates']
                    },
                    pages: {
                        forms: ['pages_filter']
                    }
                },
            }
        }
    },
    getters: {
        currentFormsByHandle(){
            const templatesStore = useTemplatesStore()
            const handle = templatesStore.currentTemplate?.handle
            if(!handle) return {}
            const currentFormHandle = this.formsByHandle[templatesStore.currentTemplate.handle]
            return mapValues(currentFormHandle, (pageForms) => {
                return {
                    ...pageForms,
                    forms: pageForms.forms?.map(entry => {
                        return {
                            form: this.formTemplates[entry],
                            props: get(pageForms, `props.${entry}`, {})
                        }
                    }).map(entry => {
                        return {
                            ...entry.form,
                            // This async is a workaround, we can't add the import to the state. (vue dev tools tries to stringify the component and triggers max stack size)
                            componentIs: () => import(`~/components/Forms/${entry.form.componentIs}`),
                            componentProps: entry.props
                        }
                    }) ?? []
                }
            })
        },
    },
})
